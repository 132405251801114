
import getJSONLD from '~/utils/structured-data';
import { commonProps } from '../../utils/prismic';
import FreespeePageTracking from '~/mixins/freespee-page-tracking';

export default {
  mixins: [FreespeePageTracking],
  props: commonProps,
  head() {
    const jsons = this.slice.data?.contactsItem.map((contact) => {
      return getJSONLD('contact', contact);
    });

    return {
      script: jsons || [],
    };
  },
};
